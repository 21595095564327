<template>
  <p>{{ tiers }}</p>
</template>

<script>
export default {
    props: ["data", "index", "column"],
    computed:{
        tiers(){
            return (this.data.point_type.length==0)?  '-' : _.map(this.data.point_type,'type_name').join(", ");
        }
    }
}
</script>