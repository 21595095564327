<template>
    <div>
        <base-header :base-title="headTitle"></base-header>
        <!-- begin:: Content -->
        <div class="formula-loyalitas kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <!-- <auto-refresh
                            :table-name="tableData.tableName"
                            :on-refresh="fetch"
                        ></auto-refresh> -->
                            <template
                                v-if="checkPermission('LoyaltyFormulaLoyaltyResource.POST.store')">
                                &nbsp;&nbsp;
                                <base-button-modal
                                    :button-text="modalText"
                                    :button-icon="modalIcon"
                                    :button-name="modalName"
                                    :onButtonModalClicked="showModal"
                                ></base-button-modal>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet__body">
                    <crud-table
                        ref="crudTable"
                        :table-options="tableOptions"
                        :repository="repository"
                        :permission="permission"
                        :is-reload-data="isReloadData"
                        @on-click-edit="onClickEdit"
                        @on-click-delete="onClickDelete"
                        @on-click-view="onClickView"
                    ></crud-table>
                </div>
                <form-modal v-if="isShowModal" @event="fetch"></form-modal>
            </div>
        </div>
        <!-- end:: Content -->
    </div>
</template>

<script>
import BaseTitle from "./../../../components/_base/BaseTitle";
import BaseHeader from "./../../../components/_base/BaseHeader";
import BaseButtonModal from "./../../../components/_base/BaseButtonModal";
import LoadSpinner from "./../../../components/_general/LoadSpinner";

import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
import { mapState, mapMutations, mapActions } from "vuex";
import CrudTable from "./../base/crud-table/crudTable";
const TableRepository = RepositoryFactory.get("formulaLoyaltyRepository");
import ColumnTiers from '@/components/_general/columnTableLoyalty/ColumnTiers.vue'
import ColumnDate from '@/components/_general/columnTableLoyalty/ColumnDate.vue'
import ColumnPointType from '@/components/_general/columnTableLoyalty/ColumnPointType.vue'
import ColumnPercentage from '@/components/_general/columnTableLoyalty/ColumnPercentage.vue'


export default {
    components: {
        BaseTitle,
        BaseHeader,
        BaseButtonModal,
        CrudTable,
        FormModal: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(import("./FormModal.vue"));
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    data() {
        var vx = this;
        return {
            isReloadData:false,
            headTitle: this.$i18n.t("module.formulaLoyalty"),
            modalText: this.$i18n.t("button.addFormula"),
            modalIcon: "flaticon2-add-1",
            repository: TableRepository,
            permission: {
                view: this.checkPermission('LoyaltyFormulaLoyaltyResource.GET.show.Integer.key'),
                edit: this.checkPermission('LoyaltyFormulaLoyaltyResource.PUT.update.Integer.key'),
                delete: this.checkPermission('LoyaltyFormulaLoyaltyResource.DELETE.destroy.Integer.key'),
                },
            tableOptions: {
                name: "CRUD_TABLE",
                columns: [
                    "id", "priority", "formula_name", "rStartDate", "rEndDate", "transaction_amount_min", "transaction_amount_max", "point", "rPointPercentage", "transaction_min", "transaction_max", "point_min", "point_max", "rPointType", "rTiers","actions"
                ],
                sortable: [
                    "id", "priority", "formula_name", "rStartDate", "rEndDate", "transaction_amount_min", "transaction_amount_max", "point", "rPointPercentage", "transaction_min", "transaction_max", "point_min", "point_max", "rPointType", "rTiers"
                    ],
                customColumn: {
                    rStartDate:"start_date",
                    rPointPercentage:"point_percentage",
                    rEndDate:"end_date",
                    rTiers:"tiers",
                    rPointType:'point_type'
                },
                orderBy: { column: 'id', ascending:false },
                templates: {
                    rStartDate:ColumnDate,
                    rEndDate:ColumnDate,
                    rTiers:ColumnTiers,
                    rPointType:ColumnPointType,
                    rPointPercentage:ColumnPercentage,
                    // transaction_amount_min: function (h, row, index) {
                    //     console.log(row);
                    //     return new Intl.NumberFormat().format(row.transaction_amount_min); // row.transaction_amount_min;
                    // }
                },
                headings: {
                    "id":"ID",
                    "priority":this.$t("formulaLoyalty.priority"),
                    "formula_name":this.$t("formulaLoyalty.formula_name"),
                    "rStartDate":this.$t("formulaLoyalty.start_date"),
                    "rEndDate":this.$t("formulaLoyalty.end_date"),
                    "transaction_amount_min":this.$t("formulaLoyalty.transaction_amount_min"),
                    "transaction_amount_max":this.$t("formulaLoyalty.transaction_amount_max"),
                    "point":this.$t("formulaLoyalty.point"),
                    "rPointPercentage":this.$t("formulaLoyalty.point_percentage"),
                    "transaction_min":this.$t("formulaLoyalty.transaction_min"),
                    "transaction_max":this.$t("formulaLoyalty.transaction_max"),
                    "point_min":this.$t("formulaLoyalty.point_min"),
                    "point_max":this.$t("formulaLoyalty.point_max"),
                    "rPointType":this.$t("formulaLoyalty.point_type"),
                    "rTiers":this.$t("formulaLoyalty.tiers")
                },
                columnsFilter: [
                    { key:"id", type:"number"},
                    { key:"priority", type:"string"},
                    { key:"formula_name", type:"string"},
                    { key:"rStartDate", type:"date"},
                    { key:"rEndDate", type:"date"},
                    { key:"transaction_amount_min", type:"number"},
                    { key:"transaction_amount_max", type:"number"},
                    { key:"point", type:"number"},
                    { key:"rPointPercentage", type:"number"},
                    { key:"transaction_min", type:"number"},
                    { key:"transaction_max", type:"number"},
                    { key:"point_min", type:"number"},
                    { key:"point_max", type:"number"},
                    // { key:"rPointType", type:"string"},
                    // { key: "rTiers", type:"string"}
                ],
                customListFilter: {
                    rTiers : {
                        repo : 'loyaltyTierRepository',
                        values: [],
                        renderSelect : function(val){
                            return (val.name) ? val.name : '';
                        },
                        type: 'multiselect',
                    },
                    rPointType : {
                        repo : 'transactionPointTypeRepository',
                        values: [],
                        renderSelect : function(val){
                            return (val.type_name) ? val.type_name : '';
                        },
                        type: 'multiselect',
                    },
                },
            }
        };
    },

    watch: {
        isShowModal: function(isTrue) {
            if (!isTrue) {
                this.setEditData(null);
                let modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
                if(!_.isUndefined(modalBackdrop)){
                    modalBackdrop.parentNode.removeChild(modalBackdrop);
                }
            }
        },
        shouldRefreshTable: function(newValue, oldValue) {
            if (newValue === true) {
                this.$refs.crudTable.refreshTable();
            }
        }
    },
    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            isLoaded: state => state.isLoaded,
            table: state => state.table,
            isShowModal: state => state.isShowModal,
            showModalMode: state => state.showModalMode,
            shouldRefreshTable: state => state.shouldRefreshTable,
        })
    },
    methods: {
        fetch(){
            this.refreshData(true);
            this.hideModal();
        },
        ...mapActions({
            deleteById: 'Crud/deleteById'
        }),
        ...mapMutations({
            refreshData: 'Crud/REFRESH_TABLE',
            showModal:'Crud/SHOW_MODAL',
            hideModal:'Crud/HIDE_MODAL',
            setRepository: 'Crud/SET_REPOSITORY',
            setModal: 'Crud/SET_MODAL',
            setupTable: 'Crud/SETUP_TABLE',
            setEditData: 'Crud/SET_EDIT_DATA',
            clearData: 'Crud/CLEAR_DATA'
        }),

        checkPermission:function (method) {
            let access = checkPermission.isCanAccess(method);
            return access;
        },
        onClickEdit: function(data) {
            if( data.start_date !== null){
                data.start_date = moment(data.start_date).format('YYYY-MM-DD');
            }
            if( data.end_date !== null){
                data.end_date = moment(data.end_date).format('YYYY-MM-DD');
            }
            this.$store.commit("Crud/SET_EDIT_DATA", data);
            this.$store.commit("Crud/SHOW_MODAL");
        },
        onClickDelete: function(data) {
            this.$store.dispatch("Crud/deleteById", {
                deleteRepository: TableRepository.delete,
                id: data.id,
                name: data.formula_name
            });
        },
        onClickView: function(data) {
            this.$store.commit("Crud/SET_VIEW_DATA", data);
            this.$store.commit("Crud/SHOW_MODAL");
        }
    },
    created() {
        this.setRepository(TableRepository);
        this.setModal("modalFormulaLoyalitas");
    },

    mounted() {
        this.hideModal();
    }
};
</script>
