<template>
    <p>{{ getFormatedDate }}</p>
</template>

<script>
export default {
    props: ["data", "index", "column"],
     computed:{
        getFormatedDate(){
            let customDateColumn = {
                rStartDate:'start_date',
                rEndDate:'end_date',
            };
            return this.data[customDateColumn[this.column]] ?  moment(this.data[customDateColumn[this.column]]).format('DD MMMM YYYY HH:MM') : '-';
        }
    },
}
</script>