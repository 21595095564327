<template>
  <p>{{ getFormated }}</p>
</template>

<script>
export default {
    props: ["data", "index", "column"],
    computed:{
        getFormated(){
            if(this.data.point_percentage != null){
                return this.data.point_percentage+'%';
            }
            return '';
        }
    },
}
</script>